import React from "react";
import "../../index.css";
const Services = () => {
  return (
    <>
      <section className="f2f2f2 py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 className="mb-3">Our Mission</h4>
              <div className="sub_underscore"> </div>
              <p className="mb-3">
                classes can be used to control the horizontal gutter widths. The
                .container or .container-fluid parent may need to be adjusted if
                larger gutters are used too to avoid unwanted overflow, using a
                matching padding utility. For example, in the following example
                we’ve increased the padding with
              </p>
            </div>
            <div className="col-md-6">
              <h4 className="mb-3">Our Vision</h4>
              <div className="sub_underscore"> </div>
              <p className="mb-3">
                classes can be used to control the horizontal gutter widths. The
                .container or .container-fluid parent may need to be adjusted if
                larger gutters are used too to avoid unwanted overflow, using a
                matching padding utility. For example, in the following example
                we’ve increased the padding with
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-12 mb-3">
              <h2 className="mb-3">Our Services</h2>
              <div className="underscore mb-3"> </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <h4 className="mb-3">Web Designing</h4>
                  <div className="sub_underscore"> </div>
                  <p className="mb-3">
                    classes can be used to control the horizontal gutter widths.
                    The .container or .container-fluid parent may need to be
                    adjusted if larger gutters are used too to avoid unwanted
                    overflow, using a matching padding utility. For example, in
                    the following example we’ve increased the padding with
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <h4 className="mb-3">Development</h4>
                  <div className="sub_underscore"> </div>
                  <p className="mb-3">
                    classes can be used to control the horizontal gutter widths.
                    The .container or .container-fluid parent may need to be
                    adjusted if larger gutters are used too to avoid unwanted
                    overflow, using a matching padding utility. For example, in
                    the following example we’ve increased the padding with
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <h4 className="mb-3">Digital Marketing</h4>
                  <div className="sub_underscore"> </div>
                  <p className="mb-3">
                    classes can be used to control the horizontal gutter widths.
                    The .container or .container-fluid parent may need to be
                    adjusted if larger gutters are used too to avoid unwanted
                    overflow, using a matching padding utility. For example, in
                    the following example we’ve increased the padding with
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Services;
