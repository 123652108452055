import React from "react";
import Footer from "../layOut/footer";
class Contact extends React.Component {
  render() {
    return (
      <div>
        <section className="py-4 text-center bg-primary">
          <h1 className="text-white">Contact Page</h1>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15251.635312226572!2d81.93127775!3d17.125946050000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a379e21ce7540e9%3A0x6489275602782a94!2sMurari%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1693122601111!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  title="murari"
                />
              </div>
              <div className="col-md-4">
                <div className="card shadow">
                  <div className="card-body">
                    <h4 className="mb-3">Mail Us</h4>
                    <div className="sub_underscore"> </div>
                    <form>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label">
                          Email{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name@example.com"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="+xx-xxxxxxxxx"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label">
                          Message
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"></textarea>
                      </div>
                      <button className="btn btn-primary">Send Message</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Contact;
