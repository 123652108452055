import React from "react";
import Sliders from "../layOut/slider";
import Services from "../layOut/services";
import Footer from "../layOut/footer";
class Home extends React.Component {
  render() {
    return (
      <div>
        <Sliders />
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mb-3">About Page</h2>
                <div className="underscore"> </div>
                <p className="mb-3">
                  classes can be used to control the horizontal gutter widths.
                  The .container or .container-fluid parent may need to be
                  adjusted if larger gutters are used too to avoid unwanted
                  overflow, using a matching padding utility. For example, in
                  the following example we’ve increased the padding with
                </p>
                <button className="btn btn-primary">Read More</button>
              </div>
            </div>
          </div>
        </section>
        <Services />
        <Footer />
      </div>
    );
  }
}
export default Home;
