import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <section className="bg-dark pt-5 pb-3 text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <h6 className="mb-3">Company Info</h6>
            <div className="sub_underscore1"> </div>
            <p className="mb-3">
              classes can be used to control the horizontal gutter widths. The
              .container or .container-fluid parent may need to be adjusted if
              larger gutters are used too to avoid unwanted overflow.
            </p>
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-3">Quick Links</h6>
            <div className="sub_underscore1"> </div>
            <ul className="mb-3 quick_links p-0 m-0">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-3">Contact Info</h6>
            <div className="sub_underscore1"> </div>
            <p className="mb-3">Phone : xx-xxxxxxxx </p>
            <p className="mb-3">Email : test@test.com </p>
            <p className="mb-3">Website : www.test.com </p>
          </div>
        </div>
        <div className="row text-white text-center pt-3 border_dots">
          <span>&copy; 2023, All Rights Reserved.</span>
        </div>
      </div>
    </section>
  );
};
export default Footer;
